<template>
  <b-card no-body>
    <b-card-header>
      <div class="d-flex align-items-center">
        <b-card-title class="ml-25"> Project Activities </b-card-title>
      </div>
    </b-card-header>

    <!-- timeline -->
    <b-card-body>
      <vue-perfect-scrollbar :settings="60" style="height: 42vh">
        <app-timeline>
          <app-timeline-item
            :variant="activity.is_done == true ? 'success' : 'primary'"
            v-for="(activity, index) in activities"
            :key="index"
            v-if="activities.length"
          >
            <div
              class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1"
            >
              <h6>
                {{ activity.start_date | time_format_short }} -
                {{ activity.end_date | time_format_short }}
                ({{ activity.end_date | date_short }})
              </h6>
              <small class="timeline-item-time text-nowrap text-muted ml-1">{{
                getHour(activity.done_duration)
              }}</small>
            </div>
            <b-badge variant="primary" class="mb-1"
              >{{ activity.done_percentage }}%
              <span v-if="activity.done_qty > 0"
                >- {{ activity.done_qty }} Quantity Done</span
              ></b-badge
            >
            <p>{{ activity.remark }}</p>
            <b-avatar-group size="35px">
              <b-avatar
                v-for="(employee, employee_index) in activity.work_employees"
                :key="employee_index"
                v-if="activity.work_employees.length && employee.employee"
                variant="secondary"
                v-b-tooltip.hover
                :title="employee.employee.name"
                :text="getChar(employee.employee.name)"
                class="pull-up"
              />
            </b-avatar-group>
            <div class="w-100" style="margin-top: 10px">
              <small class="mb-0 pb-0 text-muted" v-if="activity.task"><b>Task : </b> {{ activity.task.title }}</small><br>
              <small class="mb-0 pb-0 text-muted" v-if="activity.main_task"><b>Main Task : </b> {{ activity.main_task.title }}</small>
            </div>
          </app-timeline-item>
        </app-timeline>
      </vue-perfect-scrollbar>
      <!--/ timeline -->
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BImg,
  BMedia,
  BMediaBody,
  BMediaAside,
  BAvatar,
  BAvatarGroup,
  BBadge,
  VBTooltip,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
/* eslint-disable global-require */
export default {
  props: ["id"],
  components: {
    BCard,
    BBadge,
    BImg,
    BCardBody,
    BCardHeader,
    BCardTitle,
    AppTimeline,
    AppTimelineItem,
    BMedia,
    BAvatar,
    BMediaBody,
    BMediaAside,
    BAvatarGroup,
    VuePerfectScrollbar,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      activities: {},
      avatars: [
        {
          userImg: require("@/assets/images/portrait/small/avatar-s-9.jpg"),
          name: "Billy Hopkins",
        },
        {
          userImg: require("@/assets/images/portrait/small/avatar-s-6.jpg"),
          name: "Amy Carson",
        },
        {
          userImg: require("@/assets/images/portrait/small/avatar-s-8.jpg"),
          name: "Brandon Miles",
        },
        {
          userImg: require("@/assets/images/portrait/small/avatar-s-7.jpg"),
          name: "Daisy Weber",
        },
        {
          userImg: require("@/assets/images/portrait/small/avatar-s-20.jpg"),
          name: "Jenny Looper",
        },
      ],
    };
  },
  mounted() {
    this.getResults();
  },
  methods: {
    getHour(hour) {
      if (hour > 0) {
        let day = 0;
        let ret_hour = 0;
        let ret_minute = 0;
        if (hour >= 8) {
          let hour_data = hour % 8;
          day = (hour - hour_data) / 8;

          if (hour_data >= 1) {
            let minutes = hour_data % 1;
            ret_hour = hour_data - minutes;
            ret_minute = Math.round(minutes * 60);
          } else {
            ret_minute = Math.round(hour_data * 60);
          }
        } else {
          if (hour >= 1) {
            let minutes = hour % 1;
            ret_hour = hour - minutes;
            ret_minute = Math.round(minutes * 60);
          } else {
            ret_minute = Math.round(hour * 60);
          }
        }
        return (
          (day > 0 ? day + " Day " : "") +
          ((day > 0 && ret_minute > 0) || ret_hour > 0
            ? ret_hour + " Hour "
            : "") +
          (ret_minute > 0 ? ret_minute + " Minutes" : "")
        );
      } else {
        return 0;
      }
    },
    getChar(text) {
      return text
        .replace(/[^a-zA-Z0-9 ]/g, "")
        .split(" ")
        .map((word) => word.charAt(0))
        .join("")
        .toUpperCase()
        .substring(0, 3);
    },
    async getResults() {
      const activities = await this.callApi(
        "post",
        "/app/project/get/activities/" + this.id
      );
      if (activities.status == 200) {
        this.activities = activities.data.activities;
      }
    },
  },
};
</script>
